import { defineComponent, ref } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default defineComponent({
    mixins: [validationMixin],
    validations: {
        form: {
            message: {
                required
            }
        }
    },
    setup(_, { emit }) {
        const form = ref({
            message: ''
        });
        const maxMessageLength = ref(500);
        const remainingSymbols = ref(maxMessageLength.value);
        const handleChatInput = (event) => {
            // emit typing event
            emit('typing');
            // show how many symbols left for typing
            remainingSymbols.value = maxMessageLength.value - event.length;
        };
        const handleSubmit = form => {
            if (form.$error) {
                return;
            }
            emit('submit', form.message.$model);
            form.$reset();
            form.message.$model = '';
            remainingSymbols.value = maxMessageLength.value;
        };
        return {
            form,
            maxMessageLength,
            remainingSymbols,
            handleChatInput,
            handleSubmit
        };
    }
});
