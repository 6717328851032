import { computed, defineComponent } from '@vue/composition-api';
import { useChatVenueQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { VenueRoutes } from '@/Venue/venue.const';
export default defineComponent({
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const { result } = useChatVenueQuery(props);
        const venue = computed(() => result.value?.specific_venue);
        const image = computed(() => venue.value?.venue_images?.[0]?.image_url);
        return {
            venue,
            image,
            VenueRoutes,
            useGetLocalizedPath
        };
    }
});
