var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"chat"},[(_vm.channels && !_vm.channels.length && !_vm.loading)?_c('ChatNoMessages',{attrs:{"has-support-button":true},on:{"support-called":function($event){_vm.openSupportChat();
      _vm.startLoader();}}}):_c('section',{class:['chat-dialogs', { 'chat-dialogs--opened': _vm.dialogsOpened }]},[(_vm.$mq && _vm.$mq.phone)?_c('div',{staticClass:"chat-dialogs-active",on:{"click":function($event){_vm.dialogsOpened = !_vm.dialogsOpened}}},[(_vm.dialog)?_c('div',{staticClass:"chat-dialogs-active__item"},[_c('div',{staticClass:"chat-dialogs-active__avatar"},[_c('AvatarViewer',{attrs:{"image":_vm.dialog.image,"width":40,"height":40}})],1),_c('div',{staticClass:"chat-dialogs-active-info"},[_c('p',{staticClass:"chat-dialogs-active__title"},[_vm._v(" "+_vm._s(_vm.dialog.name)+" ")]),(_vm.dialog.booking)?_c('span',{staticClass:"chat-dialogs-active__id"},[_vm._v(" ID "+_vm._s(_vm.dialog.booking.booking_id)+" ")]):_vm._e()])]):_c('p',{staticClass:"chat-dialogs-active__title"},[_vm._v(" "+_vm._s(_vm.$t('chat.dialogs.select'))+" ")]),_c('i',{class:[
          {
            'fa-solid fa-chevron-down fa-xs': !_vm.dialogsOpened,
            'fa-solid fa-chevron-up fa-xs': _vm.dialogsOpened
          }
        ]})]):_vm._e(),(_vm.dialogsOpened || (_vm.$mq && !_vm.$mq.phone))?_c('div',{staticClass:"chat-dialogs-list"},[_c('div',{staticClass:"chat-dialogs-heading"},[_c('p',{staticClass:"chat-dialogs__title"},[_vm._v(_vm._s(_vm.$t('chat.dialogs.title')))]),_c('Access',{attrs:{"role":[_vm.UserRoleState.Booker, _vm.UserRoleState.Vendor]}},[_c('WMButton',{staticClass:"accent support-chat-button",attrs:{"small":"","icon-class-name":"fa-solid fa-headset fa-xs"},on:{"click":function($event){_vm.openSupportChat();
              _vm.startLoader();}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('chat.dialogs.support')))])])],1)],1),_c('ul',{staticClass:"chat-dialogs__list"},[_vm._l((_vm.dialogs),function(d){return _c('Dialog',{key:d.id,attrs:{"value":d},on:{"select":_vm.closeDialogs,"remove":function($event){_vm.toRemove = $event}}})}),(_vm.isSupportChatLoading)?_c('InfiniteLoading',{attrs:{"spinner":"default"}}):_vm._e(),_c('InfiniteLoading',{on:{"infinite":_vm.onLoadMore}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],2)]):_vm._e()]),(_vm.channels.length)?_c('section',{staticClass:"chat-thread"},[_c('router-view')],1):_vm._e(),_c('WMModal',{staticClass:"chat-remove-modal",model:{value:(_vm.toRemove),callback:function ($$v) {_vm.toRemove=$$v},expression:"toRemove"}},[_c('h1',[_vm._v(_vm._s(_vm.$t('chat.dialogs.remove.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('chat.dialogs.remove.description')))]),_c('div',{staticClass:"chat-remove-modal-actions"},[_c('WMButton',{attrs:{"small":"","color":"danger"},on:{"click":function($event){return _vm.onRemove(_vm.toRemove)}}},[_vm._v(" "+_vm._s(_vm.$t('chat.dialogs.remove.actions.remove'))+" ")]),_c('WMButton',{attrs:{"color":"accent","ghost":"","small":""},on:{"click":function($event){_vm.toRemove = null}}},[_vm._v(" "+_vm._s(_vm.$t('chat.dialogs.remove.actions.cancel'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }