import { computed, defineComponent, ref } from '@vue/composition-api';
import InfiniteLoading from 'vue-infinite-loading';
import { apolloClient } from '@/main';
import { SupportChatDocument, UserRoleState } from '@/generated-types/graphql.types';
import Access from '@/components/Access.vue';
import AvatarViewer from '@/Account/components/AvatarViewer.vue';
import { useChatPaginatedChannels } from '@/shared/composables/useChatPaginatedChannels';
import Dialog from '@/Chat/components/Dialog.vue';
import ChatNoMessages from '@/Chat/components/ChatNoMessages.vue';
import { ChatRoutes } from '@/Chat/chat.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        Dialog,
        InfiniteLoading,
        ChatNoMessages,
        Access,
        AvatarViewer
    },
    setup(_, context) {
        const { root } = context;
        const { channels, loadMore, loading } = useChatPaginatedChannels();
        const dialogsOpened = ref(false);
        const toRemove = ref(null);
        const dialogs = computed(() => {
            const data = channels.value
                .sort((a, b) => Date.parse(b.state.last_message_at) -
                Date.parse(a.state.last_message_at))
                .map(({ data }) => ({
                id: data?.id,
                space: data?.space,
                booking: data?.booking,
                venue: data?.venue,
                name: data?.name,
                image: data?.created_by?.avatar_link
            }));
            // Redirect to the first dialog from the list (if any) from the /chat page
            if (data[0] && !root.$route.params.chatId) {
                globalThis.$router.push(useGetLocalizedPath(`${ChatRoutes.CHAT}/${data[0].id}`));
            }
            return data;
        });
        const isSupportChatLoading = ref(false);
        const closeDialogs = () => {
            if (root.$mq?.phone) {
                dialogsOpened.value = false;
            }
        };
        const dialog = computed(() => {
            if (!root.$mq?.phone) {
                return null;
            }
            return dialogs.value?.find(({ id }) => root.$route.params.chatId === id);
        });
        const onLoadMore = async (state) => {
            const { completed } = await loadMore();
            state.loaded();
            if (completed) {
                state.complete();
            }
        };
        const onRemove = (dialog) => {
            const channelIndex = channels.value.findIndex(({ id }) => dialog.id === id);
            const prevChannelIndex = channelIndex ? channelIndex - 1 : -1;
            const prevChannel = channels.value[prevChannelIndex];
            if (channelIndex !== undefined) {
                channels.value[channelIndex]?.hide();
                channels.value.splice(channelIndex, 1);
            }
            toRemove.value = null;
            // navigate user to the previous dialog if exists, to the main chat page otherwise
            globalThis.$router.push(useGetLocalizedPath(prevChannel ? `${ChatRoutes.CHAT}/${prevChannel.id}` : ChatRoutes.CHAT));
        };
        const startLoader = () => {
            isSupportChatLoading.value = true;
        };
        const openSupportChat = async () => {
            const { data, errors } = await apolloClient.query({
                query: SupportChatDocument,
                // Client cache disabled for this query.
                // Although the server response is the same every time,
                // additional calls are mandatory to trigger server-side channel event hooks
                fetchPolicy: 'no-cache'
            });
            if (!errors && data?.get_chat) {
                isSupportChatLoading.value = false;
                const supportChatId = data.get_chat.channel_id;
                const chatId = computed(() => root.$route.params.chatId);
                if (chatId.value !== supportChatId) {
                    globalThis.$router.push(useGetLocalizedPath(`${ChatRoutes.CHAT}/${supportChatId}`));
                }
                closeDialogs();
            }
        };
        return {
            channels,
            dialog,
            dialogs,
            dialogsOpened,
            isSupportChatLoading,
            loading,
            toRemove,
            UserRoleState,
            closeDialogs,
            onLoadMore,
            onRemove,
            openSupportChat,
            startLoader
        };
    }
});
