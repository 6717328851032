import { defineComponent, ref, computed } from '@vue/composition-api';
import AvatarViewer from '@/Account/components/AvatarViewer.vue';
import ChatSpace from '@/Chat/components/ChatSpace.vue';
import ChatVenue from '@/Chat/components/ChatVenue.vue';
export default defineComponent({
    components: {
        AvatarViewer,
        ChatSpace,
        ChatVenue
    },
    props: {
        message: {
            type: Object,
            default: null
        },
        channel: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const isOnline = ref(false);
        const messageUserChatId = props.message?.user?.id;
        const channelMembers = props.channel?.state?.members;
        isOnline.value = channelMembers[messageUserChatId]?.user?.online;
        const isYou = computed(() => Number(props.user?.account_id) ===
            Number(props.message?.user?.account_id));
        /**
         * Check if message is read by the recipients
         * Message is seen when the recipient `last_read` is greater or equal than the message `created_at`
         * https://getstream.io/chat/docs/react/unread_channel/?language=js
         * @returns {boolean}
         */
        const isMessageRead = computed(() => {
            const _users = props.channel?.state?.read;
            const messageCreatedAt = props.message?.created_at;
            for (const _user in _users) {
                if (Number(_users[_user].user.account_id) ===
                    Number(props.user?.account_id)) {
                    // skip current user
                    continue;
                }
                if (_users[_user].last_read >= messageCreatedAt) {
                    return true;
                }
            }
            return false;
        });
        props.channel?.on(event => {
            const watchingUserId = event?.user?.id;
            if (watchingUserId === messageUserChatId &&
                event.type === 'user.watching.start') {
                isOnline.value = true;
            }
            else if (watchingUserId === messageUserChatId &&
                event.type === 'user.watching.stop') {
                isOnline.value = false;
            }
        });
        return {
            isMessageRead,
            isOnline,
            isYou
        };
    }
});
