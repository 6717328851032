import { defineComponent } from '@vue/composition-api';
import AvatarViewer from '@/Account/components/AvatarViewer.vue';
import { ChatRoutes } from '@/Chat/chat.const';
import ChatUnreadProvider from '@/Chat/components/ChatUnreadProvider.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        AvatarViewer,
        ChatUnreadProvider
    },
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    setup() {
        return {
            ChatRoutes,
            useGetLocalizedPath
        };
    }
});
