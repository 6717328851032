import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useChatChannel } from '@/shared/composables/useChatChannel';
import ChatMessage from '@/Chat/components/ChatMessage.vue';
import SendMessage from '@/Chat/components/SendMessage.vue';
import { BookingRoutes } from '@/Booking/booking.const';
import ChatNoMessages from '@/Chat/components/ChatNoMessages.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { useGetLocalizedPath } from '@/util/globalHelpers';
const MESSAGES_LIMIT = 25;
export default defineComponent({
    components: {
        ChatMessage,
        SendMessage,
        ChatNoMessages,
        InfiniteLoading
    },
    setup(_, context) {
        const { root } = context;
        const chatId = ref('');
        const messagesRef = ref(undefined);
        const typingUserName = ref('');
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        /**
         * Watch changes of the chat ID in the route params
         */
        watch(() => root.$route.params.chatId, () => {
            if (root.$route.params.chatId) {
                chatId.value = root.$route.params.chatId;
            }
        }, { immediate: true });
        const prevMessages = ref([]);
        const channel = useChatChannel(chatId);
        const messages = computed(() => prevMessages.value.concat(channel.value?.state.messages || []));
        watch(() => messages.value[messages.value.length - 1], (message, oldMessage) => {
            if (message?.id !== oldMessage?.id) {
                channel?.value?.markRead();
                messagesRef.value?.scrollTo(0, messagesRef?.value.scrollHeight);
            }
        }, { immediate: true });
        /**
         * Handle channel typing event.
         * https://getstream.io/chat/docs/react/typing_indicators/?language=js
         * Shows somebody is typing text when any of the channel members starts typing a message.
         */
        const handleTyping = async () => {
            await channel.value?.keystroke();
            // https://getstream.io/chat/docs/react/typing_indicators/?language=js
            // start typing event handling
            channel.value?.on('typing.start', event => {
                if (Number(event.user.account_id) !== user.value.account_id) {
                    typingUserName.value = event.user.name;
                }
            });
            // stop typing event handling
            channel.value?.on('typing.stop', () => {
                typingUserName.value = '';
            });
        };
        const sendMessage = (message) => {
            const space = !channel.value?.state.messages.length
                ? channel?.value?.data?.space
                : undefined;
            const venue = !channel.value?.state.messages.length
                ? channel?.value?.data?.venue
                : undefined;
            channel.value?.sendMessage({
                text: message,
                space,
                venue
            });
            // Send stop typing indicator
            // https://getstream.io/chat/docs/react/typing_indicators/?language=js
            channel.value?.stopTyping();
        };
        const onLoadMore = async (state) => {
            const lastMessage = channel.value?.state?.messages[0];
            const channelResult = await channel.value?.query({
                messages: { limit: MESSAGES_LIMIT, id_lt: lastMessage?.id }
            });
            state.loaded();
            if (Number(channelResult?.messages?.length) < MESSAGES_LIMIT) {
                return state.complete();
            }
            prevMessages.value.unshift(channelResult?.messages);
        };
        return {
            channel,
            messages,
            messagesRef,
            typingUserName,
            user,
            BookingRoutes,
            handleTyping,
            onLoadMore,
            sendMessage,
            useGetLocalizedPath
        };
    }
});
