import { computed, ref } from '@vue/composition-api';
import uniq from 'lodash/uniqBy';
import { useChatListen } from '@/shared/composables/useChatListen';
import { useChatEffect } from '@/shared/composables/useChatEffect';
export const useChatPaginatedChannels = () => {
    const channels = ref([]);
    const page = ref(0);
    const loading = ref(false);
    const limit = 10;
    const offset = computed(() => limit * page.value);
    let resolver = null;
    const loadMore = () => {
        page.value += 1;
        return new Promise(resolve => {
            resolver = resolve;
        });
    };
    useChatListen(async (event, chat) => {
        const visible = 'channel.visible';
        if (event.type === 'notification.message_new' && event.channel) {
            const hasIndex = channels.value.findIndex(({ id }) => event.channel?.id === id);
            if (hasIndex >= 0) {
                channels.value.splice(hasIndex, 1);
            }
            const channel = {
                ...event.channel,
                data: {
                    id: event.channel.id,
                    name: event.channel.name,
                    type: event.channel.type,
                    cid: event.channel.cid,
                    frozen: event.channel.frozen,
                    created_by: {
                        avatar_link: event.channel.created_by.avatar_link
                    }
                },
                state: {
                    last_message_at: event.received_at
                }
            };
            return channels.value?.unshift(channel);
        }
        if (event.type === visible) {
            const channel = (await chat?.queryChannels({
                cid: event.cid
            }, { last_message_at: -1 }, { watch: false }))?.[0];
            return channel && channels.value?.unshift(channel);
        }
    });
    useChatEffect(async (chat, offset) => {
        loading.value = true;
        const nextChannels = await chat.queryChannels({
            members: {
                $in: [chat.user.id]
            }
        }, { last_message_at: -1 }, { watch: false, limit, offset: offset });
        channels.value = uniq(channels.value.concat(nextChannels), ({ id }) => id);
        resolver?.({ completed: nextChannels.length < limit });
        loading.value = false;
        if (nextChannels.length < limit) {
            return;
        }
    }, [offset]);
    return {
        channels,
        loading,
        loadMore
    };
};
