import { computed, ref, watch } from '@vue/composition-api';
export const useChatChannel = (channelId) => {
    const chat = computed(() => {
        return globalThis.$store.getters['$_app/newChat'];
    });
    const channel = ref(null);
    watch([channelId, chat], async ([currentId, currentChat], [prevId, prevChat] = []) => {
        if (prevId === currentId && currentChat === prevChat) {
            return;
        }
        /** Get channel by ID */
        let channels = await currentChat?.queryChannels({
            id: currentId
        }, { last_message_at: -1 }, { watch: true });
        if (!channels?.[0]) {
            /** If there is no available channel try to get hidden one */
            channels = await currentChat?.queryChannels({
                id: currentId,
                hidden: true
            }, { last_message_at: -1 }, { watch: true });
            /** Show hidden channel */
            if (channels?.[0]) {
                channels?.[0].show();
            }
        }
        channel.value = channels?.[0];
    }, { immediate: true });
    return channel;
};
