import { defineComponent, ref } from '@vue/composition-api';
import { UserRoleState } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        hasSupportButton: {
            type: Boolean,
            default: false
        }
    },
    setup(_, { emit }) {
        const callSupport = () => {
            emit('support-called');
        };
        const isLoading = ref(false);
        const startLoader = () => {
            isLoading.value = true;
        };
        return {
            isLoading,
            UserRoleState,
            callSupport,
            startLoader
        };
    }
});
