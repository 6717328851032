import { computed, defineComponent } from '@vue/composition-api';
import { useChatSpaceQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SpaceRoutes } from '@/Space/space.const';
export default defineComponent({
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const { result } = useChatSpaceQuery(props);
        const space = computed(() => result.value?.specific_space);
        const image = computed(() => space.value?.space_images?.[0]?.image_url);
        return {
            space,
            image,
            SpaceRoutes,
            useGetLocalizedPath
        };
    }
});
